import { get, sync } from 'vuex-pathify'

export default {
	data() {
		return {
			config: this.$srv('AppBarConfig', {}),
			roundAppBarMenuLocation: this.$srv('AppBarConfig.roundAppBarMenuLocation', 'before'),
			logo: this.$srv('AppBarConfig.img'),
			logoMobile: this.$srv('AppBarConfig.imgMobile'),
			searchFieldType: this.$srv('SearchBarType.type', 'regular'),
			showMobileSearch: false,
			showMegaMenu: this.$srv('AppBarConfig.showMegaMenu', false),
			megaMenuType: this.$srv('AppBarConfig.megaMenuType', 'horizontal'),
			showHorizontalMegaMenu: this.$srv('AppBarConfig.showHorizontalMegaMenu', false),
		}
	},
	listen: {
		'shop-list:loaded'() {
			this.showMobileSearch = false
			this.menuMobileDrawer = false
		},
	},
	computed: {
		type() {
			const type = this.$srv('AppBarConfig.type', 'regular')
			if (type === 'regular' || type === 'transparent') return type
			// show round appbar on mobile only
			if (type === 'round' && this.$b.mt) return 'regular'
			return 'round'
		},
		megaMenuLocation() {
			return this.config.megaMenuLocation || 'bottom'
		},
		iconPosition() {
			return this.config.iconPosition || 'right'
		},
		appbarTransparent() {
			return this.config?.transparent || this.config?.type === 'transparent'
		},
		loading: get('shop/loading'),
		forceTransparency: get('shop/forceTransparency'),
		makeAppbarTransparent() {
			return this.isHomeRoute && !this.loading && this.appbarTransparent
		},
		isHomeRoute() {
			return this.$route.name === 'home'
		},
		user: get('shop/user'),
		loginLoading: get('shop/loginLoading'),
		menuMobileDrawer: sync('shop/menuMobileDrawer'),
		order: get('cart/order'),
		orderItemsQty() {
			return this.order?.items.reduce((sum, item) => sum + item.packQty, 0)
		},
		orderTotal() {
			return this.order?.total
		},
	},
	methods: {
		goToFavorites() {
			if (this.user) {
				this.$router.push({ name: 'user.favorites' })
			} else {
				this.showLoginDrawer()
			}
		},
		showCartDrawer() {
			this.$store.set('shop/cartDrawer', true)
		},

		showLoginDrawer() {
			this.$store.set('shop/loginDrawer', true)
		},
	},
	watch: {
		showMobileSearch(v) {
			const el = this.$refs.searchMobile
			if (v) {
				el.classList.remove('search-animation-leave')
				el.classList.add('search-animation-entry')
				el.querySelector('input').focus()
			} else {
				el.classList.remove('search-animation-entry')
				el.classList.add('search-animation-leave')
				el.querySelector('input').blur()
			}
		},
	},
}
