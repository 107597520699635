<script>
export default {
	hooksKey: 'Footer',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	data() {
		return {
			dataTop: this.$srv('FooterTop', {}),
			dataBottom: this.$srv('FooterBottom', {}),
		}
	},
}
</script>

<template>
	<footer>
		<hook zone="top">
			<Footer-Top :data="dataTop" class="py-6" />
		</hook>
		<Footer-Bottom :data="dataBottom" />
	</footer>
</template>
