<script>
import { sync } from 'vuex-pathify'
import whatsappConfigMixin from '#/v-shop/vue-src/v-shop/mixins/whatsapp-config-mixin.js'

export default {
	mixins: [whatsappConfigMixin],
	data() {
		return {
			showMenu: false,
		}
	},
	computed: {
		showWhatsappChat: sync('shop/showWhatsappChat'),
	},
}
</script>

<template>
	<div>
		<UrlMatch v-for="(data, n) in whatsAppConfig" :key="n" :data="data">
			<DeviceMatch :data="data" v-if="data">
				<div>
					<transition name="fade">
						<Button
							class="whatsapp-btn"
							:class="[data.position]"
							v-if="!showWhatsappChat"
							@click="showWhatsappChat = true"
							color="green"
							fab
							large
						>
							<v-icon>mdi-whatsapp</v-icon>
						</Button>
					</transition>
				</div>
			</DeviceMatch>
		</UrlMatch>
	</div>
</template>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}

::v-deep .round-img {
	border-radius: 50%;
}

.whatsapp-btn {
	position: fixed;
	z-index: 3;
	bottom: 10px;

	@media (max-width: 900px) {
		bottom: 80px;
	}
}

.left {
	left: 20px;
}

.right {
	right: 20px;
}
</style>
