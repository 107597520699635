<script>
export default {
	props: {
		businessInfo: Object,
		textAlignDesktop: String,
		textAlignMobile: String,
	},

	computed: {
		verticalAlignment() {
			if (this.$b.td) {
				switch (this.textAlignDesktop) {
					case 'right':
						return 'align-end text-end'

					case 'center':
						return 'align-center text-center'

					default:
						return 'align-start text-start'
				}
			} else {
				switch (this.textAlignMobile) {
					case 'right':
						return 'align-end text-end'

					case 'center':
						return 'align-center text-center'

					default:
						return 'align-start text-start'
				}
			}
		},
		horizontalAlignment() {
			if (this.$b.td) {
				switch (this.textAlignDesktop) {
					case 'right':
						return 'justify-end text-end'

					case 'center':
						return 'justify-center text-center'

					default:
						return 'justify-start text-start'
				}
			} else return ''
		},
		contactInfoClasses() {
			if (this.$b.t) return 'd-flex w100'
			else return 'd-flex flex-column'
		},
	},

	cssVars: {
		selector: '.footer',
	},
}
</script>

<template>
	<div :class="[{ 'my-4': $b.mt }, verticalAlignment]" class="d-flex flex-column">
		<Media
			:src="businessInfo.logo"
			v-if="businessInfo.logo"
			:class="[$b.td ? 'mb-8' : 'mb-5', { 'mx-2': $b.t }]"
		/>

		<div
			v-if="businessInfo.contactInfo"
			:class="[$b.t ? horizontalAlignment : verticalAlignment, contactInfoClasses]"
		>
			<div
				v-for="(contact, index) of businessInfo.contactInfo"
				:key="index"
				class="footer-link d-flex base"
				:class="[{ 'mx-2': $b.t }, verticalAlignment]"
			>
				<v-icon v-if="contact.icon" class="mr-2" :color="$vars.basetext">{{ contact.icon }}</v-icon>
				<SafeLink :to="contact.link" class="link" :id="contact.labelId" v-evt-click>
					{{ contact.text }}
				</SafeLink>
			</div>
		</div>
		<div v-if="businessInfo.socialMedia" class="d-flex" :class="$b.t ? 'ma-2' : 'my-5'">
			<SafeLink
				v-for="(rrss, index) of businessInfo.socialMedia"
				:key="index"
				class="d-flex footer base"
				:to="rrss.link"
				:id="rrss.labelId"
				v-evt-click
			>
				<Media :src="rrss.img" v-if="rrss.img" class="mr-2" width="24px" />
				<v-icon v-else :color="$vars.basetext" class="mr-2">{{ rrss.icon }}</v-icon>
			</SafeLink>
		</div>
	</div>
</template>

<style scoped>
.footer-link {
	margin-bottom: 10px;
}
</style>
