import Vue from 'vue'

export default (options) => {
	const { router, store, srv, window, apis, eventer } = options
	const { shopApi } = apis

	const Shop = {}
	Shop.search = async function (searchTerm, usePreFilter) {
		searchTerm = searchTerm ? searchTerm.trim() : ''
		if (searchTerm.length < 2) return
		eventer.trigger('search:search', { searchTerm })

		let collection
		if (usePreFilter) {
			collection = srv('PreFilterConfig.preFilter')
		}

		return this.goShop({ search: searchTerm, collection })
	}

	Shop.getShopRoute = function ({ collection, categories, brand, search } = {}) {
		let filters = []
		if (!collection && !categories) {
			filters.push(process.env.VUE_APP_SHOP_ALL_KEYWORD)
		} else {
			if (collection) {
				filters.push(collection)
			}
			if (categories) {
				filters = filters.concat(categories)
			}
		}
		if (brand) {
			filters.push(`brand-${brand}`)
		}

		let query = {}
		if (search) query.search = search

		return { name: 'shop', params: { filters }, query }
	}

	Shop.goShop = async (options = {}) => router.push(Shop.getShopRoute(options)).catch(() => null)
	//Shop.goCheckout = () => (window.location.href = router.resolve({ name: 'checkout' }).href)
	Shop.goCheckout = () => router.push({ name: 'checkout' })

	const _onAutoRemovedCartItem = (result) => {
		if (result.type == 'auto_removed') {
			if (result.reason == 'unavailable') {
				return eventer.trigger(`app-message`, {
					type: 'error',
					title: 'Producto No Disponible',
					text: 'El producto no se encuentra disponbile en este momento.',
				})
			} else if (result.reason == 'different_seller') {
				return eventer.trigger(`app-message`, {
					type: 'warning',
					title: 'No es posible agregar el producto',
					text: 'Solo está permitido comprar diferentes productos de un único vendedor a la vez.\nFinaliza tu compra o vacía el carrito para agregar productos de un vendedor diferente.',
				})
			}
		}
		return false
	}
	Shop.addToCart = async ({
		productId,
		variantId,
		type = 'product',
		customization = {},
		qty = 1,
		sum = true,
		showUpsells = true,
		showCart = true,
		...options
	}) => {
		const singleProductMode = srv('purchaseMode') == 'single_product'
		if (singleProductMode) {
			showUpsells = false
			showCart = false
			sum = false
		}

		return shopApi.post({
			...options,
			url: '/cart/item',
			data: {
				qty,
				refType: type,
				refId: variantId,
				customization,
				sum,
			},
			onSuccess: async (response) => {
				const { result, order } = response.data
				store.set('cart/order', order)

				if (result) {
					if (_onAutoRemovedCartItem(result)) {
						showCart = false
						showUpsells = false
					} else {
						// result.type -> 'added | removed'
						eventer.trigger(`cart:item-${result.type}`, {
							product: result.product,
							qty: result.qty,
							order,
						})
					}
				} else {
					showCart = false
					showUpsells = false
				}

				if (showUpsells) {
					let upsells = await Shop.getUpsells({
						productId,
						loading: false,
					})
					if (upsells?.length) {
						store.set('shop/upsells', upsells)
						response.data.upsells = upsells
						await router.push({
							name: 'upsells',
							params: { id: productId },
						})
						showCart = false
					}
				}

				if (showCart) Shop.showCart()

				await options.onSuccess?.call(null, response)
				if (singleProductMode && order?.items?.length) {
					response.options.abort()
					await Shop.goCheckout()
				}
			},
		})
	}

	Shop.updateCartItem = async ({ cartItemId, qty, ...options }) => {
		return await shopApi.put({
			...options,
			url: `/cart/item/${cartItemId}`,
			data: { qty },
			onSuccess: ({ data }) => {
				let { result, order } = data
				store.set('cart/order', order)
				if (result) {
					if (_onAutoRemovedCartItem(result)) return
					eventer.trigger(`cart:item-${result.type}`, {
						product: result.product,
						qty: result.qty,
						order: order,
					})
				}
			},
		})
	}

	Shop.deleteCartItem = async ({ cartItemId, ...options }) => {
		return await shopApi.delete({
			...options,
			url: `/cart/item/${cartItemId}`,
			onSuccess: ({ data }) => {
				store.set('cart/order', data.order)
				if (data.result) {
					eventer.trigger(`cart:item-${data.result.type}`, {
						product: data.result.product,
						qty: data.result.qty,
						order: data.order,
					})
				}
			},
		})
	}

	Shop.showCart = (b = true) => {
		store.set('shop/cartDrawer', b)
	}

	Shop.getUpsells = async ({ productId, ...options }) => {
		let { data, success } = await shopApi.get({
			...options,
			url: `/catalog/product-upsells/${productId}`,
		})
		return success && data.upsellsProducts
	}

	Shop.changeLang = (langIso) => {
		if (langIso == srv('lang.iso') || !window) return
		window.location.href = router.resolve({
			query: { lang: langIso },
		}).href
	}

	options.shop = Shop
}

Vue.mixin({
	beforeCreate() {
		this.$shop = this.$options.shop || this.$root.$options.shop
	},
})
