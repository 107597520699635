<script>
import appbarMixin from '@/v-shop/appbarMixin'

export default {
	mixins: [appbarMixin],
	data() {
		return {
			scrollY: null,
			forceHover: 1,
			megaMenuShowing: false,
		}
	},
	methods: {
		scrollHandler() {
			this.scrollY = window.scrollY
		},
	},
	computed: {
		cLogo() {
			return (hover) => {
				if (this.isTransparent && !hover)
					return this.$b({
						d: this.config.imgAlt || this.config.img,
						mt: this.config.imgMobileAlt || this.config.imgMobile,
					})
				else return this.$b({ d: this.config.img, mt: this.config.imgMobile })
			}
		},
		hasAnyMegaMenu() {
			if (!this.config.showMegaMenu) return false
			return this.config.megaMenuType === 'horizontal'
				? this.$srv('MegaMenuHorizontal', []).length
				: this.$srv('MegaMenu', []).length
		},
		hasMegaMenuHorizontal() {
			return this.$srv('MegaMenuHorizontal', []).length
		},
		isTransparent() {
			return this.scrollY < 50 && this.$route.name === 'home'
		},
		submenuStyles() {
			if (this.$ssr) return
			let w = window.innerWidth - 400
			return `width: ${w > 1120 ? 1120 : w}px !important`
		},
		backgroundColor() {
			return this.isTransparent ? 'primary' : 'base'
		},
	},
	mounted() {
		window.addEventListener('scroll', this.scrollHandler)
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.scrollHandler)
	},
	cssVars: {
		selector: '.appbar',
	},
}
</script>

<template>
	<Hover v-if="$b.td" v-slot="{ hover }" unhover-on-route-loading open-delay="250" close-delay="250">
		<div
			class="appbar transition w100"
			:style="`background: var(--${isTransparent && !hover ? 'primary' : 'base'})`"
		>
			<div :class="$bem('appbar-transparent-container-desktop')">
				<div
					class="transition d-flex align-center w100 h100 px-3"
					:style="`height: ${isTransparent ? 90 : 60}px`"
				>
					<SafeLink to="/" v-if="!showMobileSearch" :class="!hasAnyMegaMenu && 'mr-4'">
						<Media :src="cLogo(hover)" />
					</SafeLink>
					<div>
						<CategoriesMegaMenu
							v-if="showMegaMenu && megaMenuType === 'vertical'"
							:btnContentColor="isTransparent && !hover ? $vars.offertext : $vars.pricetext"
							:btnText="config.megaMenuBtnText"
							:submenuStyles="submenuStyles"
							noBg
						/>
						<div v-if="config.showMegaMenu && hasMegaMenuHorizontal" style="position: relative">
							<Hover
								v-if="megaMenuType === 'horizontal' && $b.lgAndUp"
								@updateModel="showHorizontalMegaMenu = $event"
								close-delay="250"
								open-delay="250"
							>
								<Button
									:class="{ 'dialog-arrow': showHorizontalMegaMenu || megaMenuShowing }"
									class="base"
									style="background: transparent !important"
									depressed
									large
									tile
								>
									<v-icon
										:class="{ 'icon-rotate': showHorizontalMegaMenu || megaMenuShowing }"
										class="mx-0"
										:color="$vars.primarytext"
										:size="30"
										>mdi-menu</v-icon
									>
									<span
										v-if="config.megaMenuBtnText"
										class="font-1 font-weight-bold"
										style="color: var(--primarytext)"
									>
										{{ config.megaMenuBtnText }}
									</span>
								</Button>
							</Hover>
							<Hover
								v-if="showMegaMenu && megaMenuType === 'horizontal' && $b.lgAndUp"
								@updateModel="megaMenuShowing = $event"
								style="left: 20px; min-width: 1110px"
								unhover-on-route-loading
								v-slot="{ hover }"
								close-delay="250"
							>
								<div v-show="showHorizontalMegaMenu || hover" style="position: absolute; top: 52px">
									<CategoriesMegaMenuHorizontal
										v-model="forceHover"
										class="justify-start pl-3 base rounded rounded-b-0 dialog-arrow elevation-11"
										submenuClass="rounded-t-0 shadow"
										:overlay="false"
										:submenuStyle="{
											left: 0,
											'margin-left': 0,
										}"
										no-calculated-width
										no-overlay
									/>
								</div>
							</Hover>
						</div>
					</div>
					<AppBar-Bottom-MenuItems
						:btnStyle="{
							color:
								isTransparent && !hover
									? 'var(--primarytext) !important'
									: 'var(--basetext) !important',
						}"
						:activatorArrowColor="isTransparent && !hover ? $vars.primarytext : $vars.basetext"
						class="d-flex h100"
						style="max-height: 55px"
						noBgItem
					/>
					<div class="w100 d-flex justify-end">
						<!-- Desktop and Tablet Searchfield -->
						<AutocompleteSearchField
							class="searchfield w100"
							:class="isTransparent && !hover && 'searchfield--transparent'"
							:style="
								isTransparent &&
								!hover &&
								'background: transparent!important; border: 1px solid var(--primarytext)'
							"
							:iconColor="isTransparent && !hover && $vars.primarytext"
							:iconStyle="`color: var(--${
								isTransparent && !hover ? 'discounttext' : 'errortext'
							})!important`"
							:inputStyle="`color: var(--${
								isTransparent && !hover ? 'discounttext' : 'errortext'
							})!important`"
						/>
						<AppBar-Buttons
							:accountIconColor="isTransparent && !hover ? $vars.primarytext : $vars.basetext"
							:cartIconColor="isTransparent && !hover ? $vars.primarytext : $vars.basetext"
							:favIconColor="isTransparent && !hover ? $vars.primarytext : $vars.basetext"
							noUserBtnText
							noWishlistBtnText
							noCartBtnText
						/>
					</div>
				</div>
			</div>
		</div>
	</Hover>

	<Hover v-else v-slot="{ hover }">
		<div
			class="appbar w100"
			:class="$bem('appbar-transparent-container-mobile')"
			:style="`background: var(--${isTransparent && !hover ? 'primary' : 'base'})`"
			style="transition: all 0.4s ease 0s"
		>
			<v-container class="d-flex align-center justify-space-between py-3">
				<v-btn aria-label="menu" @click="menuMobileDrawer = true" icon sclass="ml-3">
					<v-icon :color="isTransparent && !hover ? $vars.primarytext : $vars.basetext" size="30"
						>mdi-menu</v-icon
					>
				</v-btn>
				<SafeLink to="/" v-if="!showMobileSearch">
					<Media :src="cLogo(hover)" />
				</SafeLink>

				<AppBar-Buttons
					:accountIconColor="isTransparent && !hover ? $vars.primarytext : $vars.basetext"
					:cartIconColor="isTransparent && !hover ? $vars.primarytext : $vars.basetext"
					:favIconColor="isTransparent && !hover ? $vars.primarytext : $vars.basetext"
					noUserBtnText
					noWishlistBtnText
					noCartBtnText
				/>
			</v-container>

			<div class="d-flex justify-center align-center w100 pa-2">
				<div class="w100">
					<!-- Mobile Searchfield -->
					<AutocompleteSearchField
						class="searchfield w100"
						:class="isTransparent && !hover && 'searchfield--transparent'"
						:style="
							isTransparent &&
							!hover &&
							'background: transparent!important; border: 1px solid var(--primarytext)'
						"
						:iconColor="isTransparent && !hover && $vars.primarytext"
						:iconStyle="`color: var(--${
							isTransparent && !hover ? 'discounttext' : 'errortext'
						})!important`"
						:inputStyle="`color: var(--${
							isTransparent && !hover ? 'discounttext' : 'errortext'
						})!important`"
					/>
				</div>
			</div>
		</div>
	</Hover>
</template>

<style scoped lang="scss">
.appbar-transparent-container-desktop {
	max-width: 1440px;
	width: 100%;
	margin: auto;
}

.transition {
	transition: all 0.4s ease 0s;
}

.transparent-mobile-search ::v-deep form.input-container {
	background: transparent !important;
	border: 1px solid var(--primarytext) !important;
}

.transparent-mobile-search ::v-deep .icon {
	color: var(--primarytext) !important;
}

.transparent-mobile-search ::v-deep input {
	color: var(--primarytext) !important;
	&::placeholder {
		color: var(--primarytext) !important;
	}
}

.searchfield {
	::v-deep input::placeholder {
		color: var(--errortext);
	}

	::v-deep i.theme--light.v-icon {
		color: var(--errortext) !important;
	}

	&--transparent {
		::v-deep input::placeholder {
			color: var(--discounttext);
		}
		::v-deep i.theme--light.v-icon {
			color: var(--discounttext) !important;
		}
	}
}

::v-deep .input-container {
	background: transparent;
}

::v-deep span .v-badge__badge {
	background-color: var(--cta) !important;
	color: var(--ctatext) !important;
}

.overflow {
	overflow-x: scroll;
}
.w80 {
	width: 40%;
	min-width: 13rem;
}

.fixedHeight {
	height: 40px;
	vertical-align: middle;
}
.search-mobile {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100px;
	z-index: 10;
	transform: translateY(-100%);
}
.search-animation-entry {
	animation: searchMobileEntry 0.3s forwards;
}

.search-animation-leave {
	animation: searchMobileLeave 0.3s forwards;
}

@keyframes searchMobileEntry {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0%);
	}
}

@keyframes searchMobileLeave {
	from {
		transform: translateY(0%);
	}
	to {
		transform: translateY(-100%);
	}
}
</style>
