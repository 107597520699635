<script>
import { sync } from 'vuex-pathify'
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	mixins: [productCardMixin],
	data() {
		return {
			loading: false,
		}
	},
	computed: {
		outOfStock() {
			return this.selectedVariant.stock.availability == 'OutOfStock'
		},
		addToCartText() {
			return this.product.hasUniqueVariant
				? this.$b({ m: 'Agregar al \n carrito', td: 'Agregar al carrito' })
				: 'Ver variantes'
		},
	},
	methods: {
		async ctaClicked() {
			return this.$shop.addToCart({
				productId: this.product.id,
				variantId: this.selectedVariant.id,
				qty: 1,
				sum: true,
				customization: {},
				loading: (v) => (this.loading = v),
				onValidation: ({ validation }) => this.$emit('validation', validation),
			})
		},
		onProductClick() {
			this.cardClick()
			this.$router.push(this.productRoute)
		},
	},
}
</script>

<template>
	<v-list-item link>
		<v-list-item-avatar @click="onProductClick">
			<Media :src="product.images[0].thumb" width="100%" aspect-ratio="1" />
		</v-list-item-avatar>
		<v-list-item-content class="link--text font-3">
			<div v-text="product.name" class="header font-1" @click="onProductClick"></div>
			<div class="d-flex justify-space-between">
				<PriceLayout
					class="d-flex flex-row-reverse justify-end mt-1"
					:prevPrice="selectedVariant.pvPrice.prevPrice"
					:price="selectedVariant.pvPrice.price"
					prev-price-class="font-2 grey--text"
					price-class="font-2 mr-2 price"
					discount-text="-"
					:to="productRoute"
					@click="cardClick()"
				/>
				<div
					v-if="!outOfStock && product.buyable"
					class="d-flex justify-center font-1"
					@click="ctaClicked"
				>
					<Button :loading="loading" :color="$vars.offertext" text small>{{ addToCartText }} </Button>
				</div>
			</div>
		</v-list-item-content>
	</v-list-item>
</template>

<style></style>
